<template>
    <div class="pr30">
        <div class="ls_flex_between ls_aic mt20" v-if="$route.query.id">
            <div class="ls_flex ls_aic">
                <div>{{ $route.query.name }}</div>
                <div class="w1 h14 bgc08 mlr10"></div>
                <div>作品：{{ $route.query.workNum }}个</div>
            </div>
            <div class="ls_flex ls_aic">
                <el-switch
                    v-model="hideIndexShow"
                    active-color="#31A458"
                    @change="
                        params.pageIndex = 1;
                        getList();
                    "
                />
                <div class="c9 ml20">隐藏已显示首页</div>
            </div>
        </div>
        <div v-else class="ls_flex_between ls_aic mt20">
            <el-radio-group
                v-model="activeName"
                fill="#31A458"
                @change="
                    params.pageIndex = 1;
                    getList();
                "
            >
                <el-radio-button label="all">所有作品</el-radio-button>
                <el-radio-button label="isShow">已显示在首页</el-radio-button>
            </el-radio-group>

            <div class="ls_flex ls_aic" v-if="activeName === 'all'">
                <el-switch
                    v-model="hideIndexShow"
                    active-color="#31A458"
                    @change="getList"
                />
                <div class="c9 ml20">隐藏已显示首页</div>
            </div>
        </div>

        <!-- 列表 -->
        <div class="mt30 ls_flex ls_flex_wrap">
            <div
                class="wp19 b-solid br8 box-bb ls_p_rel ls_ovh ls_flex_sk mb14 hover-item bgcf"
                v-for="(item, index) in list"
                :key="index"
            >
                <img
                    class="wp100 p12 box-bb"
                    :src="item.coverImage | imgFormat"
                />

                <div class="fz14 lh22 mb10 tac">{{ item.name }}</div>
                <div class="fz14 lh22 mb10 tac c9">{{ item.userName }}</div>

                <div
                    class="c3 fz14 h37 lh37 tac bt-solid ls_cp"
                    :class="{ c9: item.indexShow }"
                    @click="changeShowIndex(item)"
                >
                    {{ item.indexShow ? "取消首页显示" : "首页显示" }}
                </div>

                <!-- 浮动悬浮按钮区域 -->
                <div
                    class="ls_p_abs l0 t0 h38 wp100 ls_flex_around ls_aic box-bb bgc08 hover-box"
                >
                    <div
                        class="cf ls_cp pl30 ls_flex_center"
                        @click="share(item)"
                    >
                        <i class="iconfont icon-fenxiang mr6"></i>
                        <span class="fz14">分享</span>
                    </div>
                    <div class="bgcf w1 h20"></div>
                    <div
                        class="cf ls_cp pr30 ls_flex_center"
                        @click="viewMaterials(item)"
                    >
                        <i class="iconfont icon-tiezi mr6" />
                        <span class="fz14">用料</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="ls_flex_center mt30">
            <el-pagination
                :current-page="params.pageIndex"
                :page-sizes="[10, 15, 20, 25]"
                :page-size="params.pageSize"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </div>
    </div>
</template>

<script>
import Vue from "vue";

export default {
    data() {
        return {
            hideIndexShow: false,
            activeName: "all",

            list: [],
            params: {
                pageIndex: 1,
                pageSize: 10,
            },
            total: 0,
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            const params = JSON.parse(JSON.stringify(this.params));

            if (this.$route.query.id) {
                // 指定学生
                params["studentId"] = this.$route.query.id;
                params["hiddenType"] = this.hideIndexShow ? 2 : 0;
                this.$ajax
                    .post("/user/student/product/getList", params)
                    .then(res => {
                        this.list = res.list;
                        this.total = res.total;
                    });
            } else if (this.activeName == "all") {
                params["hiddenType"] = this.hideIndexShow ? 2 : 0;
                this.$ajax
                    .post("/product/expand/queryAllProduct", params)
                    .then(res => {
                        this.list = res.list;
                        this.total = res.total;
                    });
            } else {
                params["type"] = 2;

                this.$ajax
                    .post("/product/expand/queryList", params)
                    .then(res => {
                        this.list = res.list || [];
                        this.total = res.total;
                    });
            }
        },
        // 分享
        share(item) {
            this.$ajax
                .get("/product/createShareCode", {
                    params: { id: item.productId },
                })
                .then(res => {
                    this.$confirm(
                        <div>
                            <img
                                class="w300 h300"
                                src={Vue.filter("imgFormat")(res.data)}
                            />
                            <div class="mt25 fz18">
                                微信扫一扫，三维作品发送手机
                            </div>
                        </div>,
                        "",
                        {
                            showCancelButton: false,
                            showConfirmButton: false,
                            dangerouslyUseHTMLString: true,
                            center: true,
                        }
                    );
                });
        },
        // 查看用料
        viewMaterials(item) {
            let dom = "";
            if (!item.useMaterials) {
                this.$message.warning("暂无用料信息");
                return;
            }

            item.useMaterials.map(item => {
                dom += `<div class="ls_flex ls_aic">
                    <span class="lh26 fz14 cf21 mr10">⬤</span>
                    <span class="lh26 fz14">
                        ${item.materialName} x${item.count}
                    </span>
                </div>`;
            });

            this.$confirm(`<div>${dom}</div>`, "用料详情", {
                showCancelButton: false,
                showConfirmButton: false,
                dangerouslyUseHTMLString: true,
                width: "400px",
            });
        },
        // 首页展示/取消展示
        changeShowIndex(item) {
            let currentSchool = JSON.parse(
                localStorage.getItem("currentSchool")
            );
            if (!currentSchool.formal) {
                this.$confirm(
                    "当前为游客体验版，不支持首页设置，请联系客服开通正式版功能。\n客服热线：13635275043",
                    "温馨提示",
                    {
                        confirmButtonText: "我知道了",
                        showCancelButton: false,
                    }
                );
                return;
            }

            this.$confirm(
                `确认将此作品${item.indexShow ? "取消" : ""}展示首页吗？`,
                "温馨提示"
            ).then(() => {
                if (item.indexShow) {
                    this.$ajax
                        .post("/product/expand/delete", {
                            productExpandId: item.id,
                        })
                        .then(res => {
                            res.data
                                ? this.$message.success("已成功取消展示首页")
                                : this.$message.warning("操作失败");

                            setTimeout(this.getList, 1000);
                        });
                } else {
                    this.$ajax
                        .post("/product/expand/save", {
                            type: "2",
                            productId: item.productId,
                        })
                        .then(res => {
                            res.data
                                ? this.$message.success("已成功设置展示首页")
                                : this.$message.warning("操作失败");

                            setTimeout(this.getList, 1000);
                        });
                }
            });
        },
        handleSizeChange(val) {
            this.params.pageIndex = 1;
            this.params.pageSize = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.params.pageIndex = val;
            this.getList();
        },
    },
};
</script>
